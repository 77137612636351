var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-order-component"},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('div',{staticClass:"text-center logo"},[_c('vuetify-logo')],1),(_vm.allOrders.length > 0)?_c('div',{staticClass:"content"},[_c('h3',{staticClass:"text-center"},[_vm._v("طلباتي")]),_c('v-expansion-panels',{staticClass:"boxs",attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.allOrders),function(item){return _c('v-expansion-panel',{key:item.id,staticClass:"box"},[_c('v-expansion-panel-header',{staticClass:"content-box"},[_c('div',{class:item.status + ' status'},[_c('p',{staticClass:"state-text"},[_vm._v(_vm._s(item.order_status))])]),_c('div',{staticClass:"date-time"},[_c('span',{staticClass:"date"},[_c('i',{staticClass:"fas fa-calendar-alt icon-date"}),_vm._v(" "+_vm._s(item.date))]),_c('span',{staticClass:"time"},[_c('i',{staticClass:"far fa-clock icon-date"}),_vm._v(" "+_vm._s(item.time))])])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"des"},[_vm._l((item.products),function(product,index){return _c('p',{key:index,staticClass:"product-name"},[_vm._v(" "+_vm._s(product.pivot.quantity)+" - "+_vm._s(_vm._f("strippedContent")(product.name))+" ")])}),_c('hr'),_c('p',{staticClass:"order-number"},[_vm._v(" رقم الطلب : "),_c('strong',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(item.order_id))])]),_c('p',{staticClass:"order-total"},[_vm._v("التكلفة : "+_vm._s(item.sub_total)+" ريال")]),_c('div',{staticClass:"btns-actions"},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[(
                          item.payment_method == 'bank_transfer' &&
                          item.status == 'pending'
                        )?_c('button',{staticClass:"green-btn check custom-btn",on:{"click":function($event){return _vm.confirmPayment(item.id)}}},[_vm._v(" تأكيد الدفع "),_c('i',{staticClass:"fas fa-check"})]):_vm._e(),(
                          item.payment_method == 'credit_card' &&
                          item.status == 'pending'
                        )?_c('router-link',{staticClass:"green-btn check custom-btn",attrs:{"to":'/paymentMethod?orderId=' + item.id}},[_vm._v("تأكيد الدفع")]):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[(
                          (item.payment_method == 'bank_transfer' ||
                            item.payment_method == 'credit_card') &&
                          item.status == 'pending'
                        )?_c('button',{staticClass:"close-btn custom-btn",on:{"click":function($event){return _vm.cancelOrder(item.id)}}},[_vm._v(" الغاء "),_c('i',{staticClass:"fas fa-times"})]):_vm._e()])],1)],1)],2)])],1)}),1)],1):_c('div',[_c('h3',{staticClass:"text-center"},[_vm._v("لا يوجد طلبات")]),_c('h3',{staticClass:"text-center",staticStyle:{"text-align":"center","margin-top":"20px"}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"/"}},[_vm._v("العودة للصفحة الرئيسية")])])])])],1),_c('v-snackbar',{attrs:{"color":_vm.color,"top":"","right":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.messsage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }